
import { LockIcon, CheckIcon, DoDisturbIcon } from "../icons"
import { TextField, FormControl } from "@suid/material";
import { createSignal } from "solid-js";

interface FieldEmailProps {
  id: string;
  label?: string;
  textValue?: string;
  helperText?: string;
  error?: boolean;
  width?: number;
  dontShowIcon?: boolean;
  valueOnChange: (value: string) => void;
}

const FieldPassword = (props: FieldEmailProps) => {
  const [userPassword, setUserPassword] = createSignal('');

  const [value, setValue] = createSignal<string | null>(null);

  const handleInputChange = (onChange: (value: string) => void) => (event: Event) => {
    const newValue = (event.target as HTMLInputElement).value;
    onChange?.(newValue);
    setValue(newValue)
    setUserPassword(newValue)
  };

  const checkPasswordStrength = (password: string) => {
    const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/;
    return regex.test(password);
  }
  const returnCheckIcon = () => {
    return (
      <CheckIcon
        aria-label="more"
        id="long-button"
        sx={{ ml: 1 }}
        color={checkPasswordStrength(userPassword()) ? "success" : 'disabled'}
      />
    )
  }
  const returnDoDisturbIcon = () => {
    return (
      <DoDisturbIcon
        aria-label="more"
        id="long-button"
        sx={{ ml: 1 }}
        color={'error'}
      />
    )
  }
  return (
    <>
      <FormControl fullWidth >
        <TextField
          required id={props.id} label={props.label} type="password" variant="outlined"
          size="small"
          defaultValue={props.textValue}
          helperText={props.helperText}
          value={value()}
          onChange={handleInputChange(props.valueOnChange)}
          error={props.error}
          InputProps={{
            endAdornment: (
              <LockIcon
                aria-label="more"
                id="long-button"
                sx={{ ml: 1 }}
                color={checkPasswordStrength(userPassword()) ? "success" : "disabled"}
              />
            ),
          }}
        />
      </FormControl>
    </>
  )
}
export { FieldPassword }
/*
<Show
when={!props.dontShowIcon}>
{checkPasswordStrength(userPassword()) ? <CheckIcon color="success" /> : <DoDisturbIcon color="error" />}
</Show>
*/

/*

                                endAdornment: (checkPasswordStrength(userPassword()) ? (
                                    
                                    
                                  <CheckIcon
                                    aria-label="more"
                                    id="long-button" 
                                    sx={{ ml: 1 }}
                                    color={  "success" }
                                  />
                                    
                                  
                                ) : (
                                    <LockIcon
                                    aria-label="more"
                                    id="long-button" 
                                    sx={{ ml: 1 }}
                                    color={  'disabled'}
                                  />
                                )
*/